import React from 'react';
import Spline from '@splinetool/react-spline';
import '../css/common.css'
import fiberGif from '../css/images/zerone-fiber-path.gif'
import { useContext } from 'react';
import { DeviceContext } from '../App';
import Header from './Header';
import PricingPage from './PricingPage.js'
import Aboutus from './Aboutus';
import Form from './Form';

function LandingPage(props) {

    const deviceName = useContext(DeviceContext);

    const handleRedirect = (page) => {
        if(page === 'userLogin'){
            window.open('http://user.zcomm.co.in/selfcare', '_blank')
        }
        else{
            const section = document.getElementById(page);
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <Header />
            <div className='landing-section'>
                <div className='landing-page-parent container-fluid'>
                    <div className='company-title row'>
                        <div className="content col-12">
                            <h2 className='company-name'>Zerone</h2>
                            <h2 style={{display: 'flex', flexDirection: 'row'}}>Zerone</h2>
                        </div>
                        {/* <div className='col-12 col-md-12 row justify-content-center'>
                            <div className='company-5g col-3'>5G</div>
                        </div> */}
                    </div>
                    {
                                    deviceName === "phone"
                                    ? <img className='gif-container' src={fiberGif} alt="Fiber | Broadband" />
                        :<div className="spline-container row justify-content-start">
                                <Spline className='spline-obj col-10' scene="https://prod.spline.design/lSkQdxWBpp2eNXGc/scene.splinecode"/>
                                
                            </div>
                    }
                    {
                        (deviceName === "phone") && <div className='header-buttons row justify-content-center'>
                            <button className='enquire-button col-5' onClick={()=>handleRedirect("enquirePage")} >ENQUIRE</button>
                            <button className='pricing-button col-5' onClick={()=>handleRedirect("pricingPage")} >PRICING</button>

                        </div>
                    }
                </div>
            </div>
            <Aboutus/>
            <PricingPage/>
            <Form></Form>
        </div>
    );
}

export default LandingPage;