import React from 'react'

import '../css/common.css'
import zeroneLogo from '../css/images/zerone_networks.jpg'
import loginIcon from '../css/images/user_login.png'
import { useContext } from 'react';
import { DeviceContext } from '../App';

function Header() {
  const deviceName = useContext(DeviceContext);

  const handleRedirect = (page) => {
      if(page === 'userLogin'){
          window.open('http://user.zcomm.co.in/selfcare', '_blank')
      }
      else{
          const section = document.getElementById(page);
          section.scrollIntoView({ behavior: 'smooth' });
      }
  };

  return (
    <div className='header-buttons container-fluid row justify-content-between'>
      <img className='header-logo col-4 col-md-2 img-fluid' src={zeroneLogo} alt='ZERONE' />
      {
          (deviceName === "tab" || deviceName === "laptop") &&  <div className='header-btns col-md-4 row justify-content-end'>    
              <button className='enquire-button col-md-4' onClick={()=>handleRedirect("enquirePage")} >ENQUIRE</button>
              <button className='pricing-button col-md-4' onClick={()=>handleRedirect("pricingPage")} >PRICING</button>
              <div className='login-comp col-md-2'>
                  <img className='login-button img-fluid' onClick={()=>handleRedirect("userLogin")} src={loginIcon} alt='Login' />
                  <span className="tooltiptext">Login</span>  
              </div>
          </div>
      }
      {
          (deviceName === "phone") && <img className='login-button col-2 img-fluid' onClick={()=>handleRedirect("userLogin")} src={loginIcon} alt='Login' />
      }
    </div>  
  )
}

export default Header



