import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import LandingPage from './component/LandingPage.js'
import FooterComponent from './component/footer';
import PrivacyPage from './component/PrivacyPage';

export const DeviceContext = React.createContext();

function App() {
  const [screenName, setScreenName] = useState('phone');

  const resizeHandler = () => {
    if (window.innerWidth < 768){
      setScreenName(prev => "phone");
      console.log("setting size :: phone")
    }
    else if (window.innerWidth >= 768 && window.innerWidth < 1200){
      setScreenName(prev => "tab");
      console.log("setting size :: tab")
    }
    else if (window.innerWidth >= 1200){
      setScreenName(prev => "laptop");
      console.log("setting size :: lap")
    }
  }

  useEffect(() => {
    resizeHandler();
    document.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div className="App">
      <DeviceContext.Provider value={screenName}>
        <Router>
          {/* <Header /> */}
          <Routes>
            <Route path="/" element={<LandingPage/>} />
            <Route path="privacy-policy" element={<PrivacyPage />} />
          </Routes>
          <FooterComponent />
        </Router>
      </DeviceContext.Provider>
    </div>
  );
}

export default App;
